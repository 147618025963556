<template>
  <iframe :src="url" width="100%" height="100%" frameborder="0" scrolling="auto"></iframe>
</template>
<script>
export default {
  name: 'MonitoringScreen',
  data() {
    return {
      url: 'https://datav.aliyuncs.com/share/056b934559ee91ea4acf598eb7b1279d?organ_id='+localStorage.getItem('organId')
    }
  },
  mounted() {

  }
}
</script>